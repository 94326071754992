/* eslint-disable no-nested-ternary */
import React, {useCallback, useRef} from 'react';
import styled from 'styled-components';

const colorActive = inverted => ( inverted ? 'white' : 'black' );

const top = 'translate(0, calc(-100% - 1rem))';
const left = 'translate(calc(-100% - 1.7rem - 1px), 0)';
const not = 'translate(0, 0)';

const StyledWrapper = styled.div`
  position: relative;

  ${({theme}) => theme.mq.s} {
    margin-top: 1.2rem;
  }
`;

const StyledLabel = styled.label`
  font-size: ${({active, narrow}) => ( ( active && narrow ) ? '1.1rem' : '1.4rem' )};
  text-transform: uppercase;
  position: absolute;
  color: ${({theme: {color}, active, inverted}) => ( active ? color[ colorActive(inverted) ] : color.gray )};
  top: calc(.85rem + 1px);
  left: calc(.85rem + 1px);
  transition: .3s ease;
  transform: ${({active, narrow}) => ( active ? ( narrow ? top : left ) : not )};

  ${({theme}) => theme.mq.s} {
    transform: ${({active}) => ( active ? top : not )};
    font-size: ${({active}) => ( active ? '1.1rem' : '1.4rem' )};
  }
`;

const StyledDropdown = styled.select`
  font-size: 1.4rem;
  padding: .85rem;
  border: ${({theme: {color}}) => `1px solid ${color.gray}`};
  transition: .3s ease;
  width: 100%;
  box-sizing: border-box;
  appearance: none;
  text-transform: uppercase;
  -moz-appearance: textfield;
  background: transparent;
  
  &:focus {
    outline: none;
    box-shadow: ${({theme}) => `inset 0px 0px 0px 1px ${theme.color.gray}`};
  }

  & option {
    text-transform: uppercase;
    cursor: pointer;
  }
`;

const StyledError = styled.div`
  font-size: .9rem;
  min-height: 1.4rem;
  line-height: 1.4rem;
  color: firebrick;
  transition: .3s ease;
  opacity: ${({show}) => ( show ? 1 : 0 )};
`;

const Dropdown = ({
                    label, meta, inverted, narrow, input, options, onChange
                  }) => {
  const {
    active, dirty, touched, error,
  } = meta;
  const inputRef = useRef(null);
  const focusInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [ inputRef ])
  return (
    <StyledWrapper>
      <StyledLabel
        onClick={focusInput}
        narrow={narrow}
        inverted={inverted}
        active={active || dirty}
      >
        {label || input.name}
      </StyledLabel>
      <StyledDropdown ref={inputRef} {...input}>
        <option id={null} disabled/>
        {options.map(({id, label}) => <option value={id}>{label}</option>)}
      </StyledDropdown>
      <StyledError show={error && touched}>{error && touched && error}</StyledError>
    </StyledWrapper>
  );
};

export default Dropdown;
