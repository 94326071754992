import React, {useState} from 'react';
import styled from 'styled-components';
import {Field} from 'react-final-form';
import Dropzone from 'react-dropzone';
import {hexToRgba} from '../styles/theme';
import Loader from './Loader';

const filesExtensions = [ '.jpg', '.jpeg', '.png' ];
const maxSize = 2000000; //2MB

const HEIGHT = 240;

const StyledLabel = styled.label`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
  color: ${({theme}) => theme.color.white};
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: .3s;
  padding: 1rem;
  cursor: pointer;
  box-sizing: border-box;
  background: ${({theme, preview}) => preview && hexToRgba(theme.color.grayLight, 0.5)};
  opacity: ${({loading}) => ( loading ? 0 : 1 )};
`;
const StyledWrapper = styled.div`
  position: relative;
  cursor: pointer;
  transition: opacity 0s;
  height: ${HEIGHT}px;
  background-color: #777;
  opacity: ${({preview}) => preview ? 1 : .6};
  
  &:hover {
    opacity: 1;
    background: url(${({imageUrl}) => imageUrl}) no-repeat center;
    background-size: cover;
    transition: opacity .5s;
    ${StyledLabel} {
      background: ${({theme}) => hexToRgba(theme.color.grayLight, 0.65)};
    }
  }

  &:focus {
    outline: none
  }
`;

const StyledPreview = styled.img`
  vertical-align: middle;
  height: ${HEIGHT}px;
  width: 100%;
  object-fit: cover;

  &:focus {
    outline: none;
  }
`;

const StyledLoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  vertical-align: middle;
`;

const StyledClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 2px;
  height: 20px;
  width: 20px;
  z-index: 10;
  text-align: center;
  font-size: 1.8rem;
  border-radius: 100px;
  color: ${({theme}) => theme.color.white};
  background: ${({theme}) => hexToRgba(theme.color.grayLighter, 0.5)};
`;

const StyledError = styled.div`
  font-size: .9rem;
  min-height: 2.8rem;
  line-height: 1.4rem;
  color: firebrick;
  transition: .3s ease;
  opacity: ${({show}) => ( show ? 1 : 0 )};
`;

const StyledDropzone = styled.div`
  height: ${HEIGHT}px;
  position: relative;
`;

const ImageUpload = ({
                       updateImage, preview, name, label, error, removeImage, setError: setErrorProp, imageUrl,
                     }) => {
  const [ loading, setLoading ] = useState(false);

  const setError = (msg = 'Wystąpił problem z załadowaniem zdjęcia') => {
    setErrorProp(name, msg);
  };

  const removePreview = () => {
    removeImage(name);
    setError('Zdjęcie jest wymagane');
  };

  return (
    <Field name={name}>
      {() => (
        <div>
          <StyledWrapper imageUrl={imageUrl} preview={preview}>
            {preview && (
              <StyledClose onClick={removePreview}>
                &#10005;
              </StyledClose>
            )}
            <Dropzone
              maxSize={maxSize}
              multiple={false}
              accept={filesExtensions}
              onDropAccepted={acceptedFiles => {
                setLoading(true);
                acceptedFiles.forEach((file) => {
                  const reader = new FileReader();
                  reader.onerror = setError;
                  reader.onloadend = () => {
                    updateImage({preview: reader.result, file, name});
                    setLoading(false);
                  };
                  reader.readAsDataURL(file);
                });
              }}
              onDropRejected={
                ([ file ]) => {
                  const extension = file.name.split('.').pop();
                  switch (true) {
                    case !filesExtensions.includes(`.${extension.toLowerCase()}`): {
                      setError(`Pliki ${extension} nie są obsługiwane.`);
                      break;
                    }
                    case ( file.size > maxSize ): {
                      setError(`Maksymalny rozmiar pliku to ${maxSize / 1000000}MB`);
                      break;
                    }
                    default: {
                      setError();
                    }
                  }
                }
              }
            >
              {({getRootProps, getInputProps}) => (
                <>
                  <StyledDropzone {...getRootProps()}>
                    {loading && (
                      <StyledLoaderWrapper>
                        <Loader height="45px" fill="#fff"/>
                      </StyledLoaderWrapper>
                    )}
                    <input {...getInputProps()} />
                    {preview && <StyledPreview src={preview}/>}
                    <StyledLabel preview={preview} loading={loading}>{label}</StyledLabel>
                    {/*<StyledPlaceholderImage imageUrl={imageUrl} />*/}
                  </StyledDropzone>
                </>
              )}
            </Dropzone>
          </StyledWrapper>
          <StyledError show={error}>{error}</StyledError>
        </div>
      )}
    </Field>
  );
};

ImageUpload.defaultProps = {
  error: null,
};

export default ImageUpload;
