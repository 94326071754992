import React from 'react';
import styled from 'styled-components';
import {Field, FormSpy} from 'react-final-form';
import Input from '../Input';
import Dropdown from "../SecondarySlider/Dropdown";

const StyledWrapper = styled.div`
  align-self: stretch;
  width: 100%;
  justify-self: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({theme}) => theme.mq.s} {
    display: grid;
    justify-content: stretch;
    grid-gap: 0;
  }
`;

const Inputs = ({gender}) => (
  <StyledWrapper>
    <Field name="gender">
      {props => <Dropdown
        label="płeć / gender"
        options={[ {id: 'female', label: 'kobieta / female'}, {id: 'male', label: 'mężczyzna / male'} ]}
        {...props}
      />
      }
    </Field>
    <Field name="name">{props => <Input label="imię / name" {...props} />}</Field>
    <Field name="surname">{props => <Input label="nazwisko / surname" {...props} />}</Field>
    <Field name="email">{props => <Input label="email"  {...props} />}</Field>
    <Field name="bthdate">{props => <Input label="data urodzin / birth date" {...props} />}</Field>
    <Field name="bust">{props => <Input label={gender === 'male' ? "klatka piersiowa / chest" : "biust / bust"}
                                        type="number" {...props} />}</Field>
    <Field name="waist">{props => <Input label="talia / waist" type="number" {...props} />}</Field>
    <Field name="hips">{props => <Input label="biodra / hips" type="number" {...props} />}</Field>
    <Field name="height">{props => <Input label="wzrost / height" type="number" {...props} />}</Field>
    <Field name="shoe">{props => <Input label="but / shoe size" type="number" {...props} />}</Field>
    <Field name="city">{props => <Input label="miasto / city" {...props} />}</Field>
    <Field name="instagram">{props => <Input label="instagram" {...props} />}</Field>
    <Field name="phone">{props => <Input label="numer telefonu / phone number" {...props} type="number"/>}</Field>
  </StyledWrapper>
);

export default Inputs;
